import { useContext } from "react"

import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import LinearProgress from "@material-ui/core/LinearProgress"
import { makeStyles } from "@material-ui/core/styles"
import { LoadingContext } from "../contexts/LoadingContext"

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

import { verificarLocalStorageAppDados } from "../services/functions"

const useStyles = makeStyles(theme => ({
    progress: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: "1301!important",
        color: "black",
        backgroundColor: "rgb(255 255 255 / 50%)",
    },
}))

export default function Loading() {
    const { setLoading, loading } = useContext(LoadingContext)

    const aplicativoDados = verificarLocalStorageAppDados()
    //imports
    const classes = useStyles()

    const theme = createMuiTheme(
        aplicativoDados
            ? {
                  status: {
                      danger: "#e53e3e",
                      success: "#28a745",
                  },
                  palette: {
                      primary: {
                          light: `${aplicativoDados.corSitePrimaria}`,
                          main: `${aplicativoDados.corSitePrimaria}`,
                          // dark: will be calculated from palette.secondary.main,
                          contrastText: `#fff`,
                      },
                      secondary: {
                          light: `${
                              aplicativoDados.corSiteSecundaria === aplicativoDados.corSitePrimaria
                                  ? "#6c757d"
                                  : aplicativoDados.corSiteSecundaria
                          }`,
                          main: `${
                              aplicativoDados.corSiteSecundaria === aplicativoDados.corSitePrimaria
                                  ? "#6c757d"
                                  : aplicativoDados.corSiteSecundaria
                          }`,
                          contrastText: `#000000`,
                      },
                  },
              }
            : {}
    )

    return (
        <ThemeProvider theme={theme}>
            <Backdrop
                className={classes.backdrop}
                style={{ color: aplicativoDados?.corSitePrimaria }}
                open={loading}
            >
                {loading && (
                    <div
                        className={classes.progress}
                        style={{
                            zIndex: 9999,
                            position: "fixed",
                            top: 0,
                            padding: 0,
                            margin: 0,
                            left: 0,
                        }}
                    >
                        <LinearProgress color="primary" />
                    </div>
                )}

                <img
                    style={{
                        width: "40px",
                        position: "fixed",
                        borderRadius: "100%",
                    }}
                    src={aplicativoDados?.urlLogo ? aplicativoDados?.urlLogo : ""}
                />
                <CircularProgress color="inherit" size={80} />
            </Backdrop>
        </ThemeProvider>
    )
}
