export const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", function () {
            navigator.serviceWorker
                .register("/firebase-messaging-sw.js")
                .then(function (registration) {
                    console.log("ServiceWorker registration successful with scope: ", registration)
                    registration.update()
                })
                .catch(function (err) {
                    // registration failed :(
                    console.log("ServiceWorker registration failed: ", err)
                })
        })
    }
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister()
            })
            .catch(error => {
                console.error(error.message)
            })
    }
}
