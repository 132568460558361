import axios from "axios"
import { HmacMD5 } from "crypto-js"
import { descripto } from "./functions"

function encryptWithMD5(value) {
    const key = "9e4e1eb8a90ff835ec97315c048bcef2"
    return HmacMD5(value?.toString(), key).toString()
}

const url = localStorage.getItem("teste")
    ? localStorage.getItem("teste")
    : "https://wapi.appclientefiel.com.br/rest/"

axios.defaults.timeoutErrorMessage =
    "Infelizmente o sistema não respondeu nossa solicitação. Tente novamente."

var urlTeste = null //'https://dd2f974dff88.ngrok.io/clientefiel/rest/'

const api = axios.create({
    baseURL: urlTeste && window.location.host.includes("localhost") ? urlTeste : url,
})

api.interceptors.request.use(config => {
    config.headers.common["x-cf-c"] = encryptWithMD5(descripto(localStorage.getItem("esta"))?.id)
    config.headers.common["x-cf-cc"] = encryptWithMD5(
        descripto(localStorage.getItem("use"))?.cliente?.id
    )

    return config
})

export default api
