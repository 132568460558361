import { Component } from "react"
import XMLViewer from "react-xml-viewer"

const paths = [
    "delivery",
    "estabelecimentos",
    "login",
    "cadastro",
    "lojas",
    "downloads",
    "conectar-mesa",
]
const xml = `
<urlset
	xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
	xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
	xmlns:xhtml="http://www.w3.org/1999/xhtml" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
	<url>
		<loc>${window.location.origin}/</loc>
		<lastmod>2024-04-09</lastmod>
		<priority>1.00</priority>
	</url>
    ${paths.map(
        path =>
            `<url>
            <loc>${window.location.origin}/${path}</loc>
            <lastmod>2024-04-09</lastmod>
            <priority>0.80</priority>
        </url>`
    )}
</urlset>
`

export default class Sitemap extends Component {
    render() {
        return (
            <div>
                <XMLViewer xml={xml} />
            </div>
        )
    }
}
