import { useContext } from "react"
import TagManager from "react-gtm-module"
import { Redirect, Route } from "react-router-dom"
import SiteContext from "../contexts/SiteContext"

export default function RoutePersonalizado(props) {
    console.warn("====INICIO====RoutePersonalizado PROPS", props, "\n\n")
    let {
        component: Component,
        dados = false,
        anonimo = false,
        privado = false,
        catalogo = false,
        titulo = false,
        ...rest
    } = props

    const {
        aplicativoDados,
        izzalogada,
        usuarioLogado,
        estabelecimentoAtual,
        setEstabelecimentoAtual,
        setCarrinho,
        cardapio,
        carrinho,
    } = useContext(SiteContext)

    window.onscroll = () => {}

    if (dados && !aplicativoDados) {
        console.log("NÃO TEM DADOS!", props)
        return <Redirect to={"/"} />
    }

    if (privado && !usuarioLogado?.logado) {
        console.log("NÃO TEM PRIVADO!", props)
        return <Redirect to={"/"} />
    }

    if (catalogo && !cardapio.id) {
        console.log("NÃO TEM CARDAPIO!", props)
        return <Redirect to={"/"} />
    }

    if (props.location.pathname === "/" && props.location.search.includes("?chave=")) {
        return <Redirect to={"/login/Acesso" + props.location.search} />
    }

    if (aplicativoDados) {
        headAdd(aplicativoDados, rest, estabelecimentoAtual, titulo)
    }

    return <Route {...rest} component={Component} />
}

const headAdd = (aplicativoDados = {}, rest, estabelecimento = {}, titulo = false) => {
    // window.scrollTo(0, 0)

    pixelFacebook(aplicativoDados)
    tagManager(aplicativoDados, estabelecimento)
    googleAnalytics(aplicativoDados, rest)

    console.log("Establecimentos Presentes", aplicativoDados?.estabelecimentos)
    const logoAlternativa = aplicativoDados.estabelecimentos
        ? aplicativoDados?.estabelecimentos[0]?.urlLogo
        : null
    const logoApp = aplicativoDados.urlLogo ? aplicativoDados?.urlLogo : logoAlternativa

    //console.log("aplicativoDados", aplicativoDados)
    if (titulo) {
        document.title = `${aplicativoDados.projectName} - ${titulo}`
    }
    //<link rel="apple-touch-icon" href="/sitefiles/12345/678/90/apple-touch-icon.png">
    const logoIphone = document.createElement("link")
    logoIphone.setAttribute("rel", "Apple-touch-icon")
    logoIphone.setAttribute("sizes", "128x128")
    logoIphone.setAttribute("href", logoApp)
    document.head.insertAdjacentElement("beforeend", logoIphone)

    const logoIphone2 = document.createElement("link")
    logoIphone2.setAttribute("rel", "Apple-touch-icon-precomposed")
    logoIphone2.setAttribute("href", logoApp)
    document.head.insertAdjacentElement("beforeend", logoIphone2)

    const shortCurt = document.createElement("link")
    shortCurt.setAttribute("rel", "shortcut icon")
    shortCurt.setAttribute("sizes", "192x192")
    shortCurt.setAttribute("href", logoApp)
    document.head.insertAdjacentElement("beforeend", shortCurt)

    const shortCurt2 = document.createElement("link")
    shortCurt2.setAttribute("rel", "shortcut icon")
    shortCurt2.setAttribute("sizes", aplicativoDados.corSitePrimaria)
    shortCurt2.setAttribute("href", logoApp)
    document.head.insertAdjacentElement("beforeend", shortCurt2)

    const ogImage = document.createElement("meta")
    ogImage.setAttribute("property", "og:image")
    ogImage.setAttribute("content", `${logoApp}`)
    document.head.insertAdjacentElement("beforeend", ogImage)

    const ogUrl = document.createElement("meta")
    ogUrl.setAttribute("property", "og:url")
    ogUrl.setAttribute("content", `https://${aplicativoDados.urlAcesso}`)
    document.head.insertAdjacentElement("beforeend", ogUrl)

    const ogType = document.createElement("meta")
    ogType.setAttribute("property", "og:type")
    ogType.setAttribute("content", `website`)
    document.head.insertAdjacentElement("beforeend", ogType)

    const ogDescription = document.createElement("meta")
    ogDescription.setAttribute("property", "og:description")
    ogDescription.setAttribute(
        "content",
        `Faça pedidos online no delivery OFICIAL ${aplicativoDados.projectName}. Veja preços dos pratos no cardápio virtual do ${aplicativoDados.projectName} para entrega, retirada, pedido de mesa e programas de fidelidade.`
    )
    document.head.insertAdjacentElement("beforeend", ogDescription)

    const description = document.createElement("meta")
    description.setAttribute("name", "description")
    description.setAttribute(
        "content",
        `Faça pedidos online no delivery OFICIAL ${aplicativoDados.projectName}. Veja preços dos pratos no cardápio virtual do ${aplicativoDados.projectName} para entrega, retirada, pedido de mesa e programas de fidelidade.`
    )
    document.head.insertAdjacentElement("beforeend", description)

    const keywords = document.createElement("meta")
    keywords.setAttribute("name", "keywords")
    keywords.setAttribute(
        "content",
        `Site ${aplicativoDados.projectName},aplicativo ${aplicativoDados.projectName},pedir ${aplicativoDados.projectName},${aplicativoDados.projectName} delivery OFICIAL, ${aplicativoDados.projectName} delivery, ${aplicativoDados.projectName} entrega, ${aplicativoDados.projectName}, ${aplicativoDados.projectName}, cardapio ${aplicativoDados.projectName}, preços ${aplicativoDados.projectName}`
    )
    document.head.insertAdjacentElement("beforeend", keywords)

    const themeColor = document.createElement("meta")
    themeColor.setAttribute("name", "theme-color")
    themeColor.setAttribute("content", aplicativoDados.corSitePrimaria)
    document.head.insertAdjacentElement("beforeend", themeColor)

    aplicativoDados?.script
        ? document.head.insertAdjacentHTML("afterbegin", aplicativoDados?.script)
        : console.log("")
    aplicativoDados?.scriptscriptBody
        ? document.body.insertAdjacentHTML("afterbegin", aplicativoDados?.scriptscriptBody)
        : console.log("")

    const themeLinkUrl = document.createElement("link")
    themeLinkUrl.setAttribute("rel", "icon")
    themeLinkUrl.setAttribute("href", logoApp)
    document.head.insertAdjacentElement("beforeend", themeLinkUrl)
}

const pixelFacebook = ({ pixelFacebook = false }) => {
    try {
        if (pixelFacebook) {
            window.dispatchEvent(
                new CustomEvent("criarPixel", {
                    detail: {
                        tag: pixelFacebook,
                    },
                })
            )
        }
    } catch (error) {}
}

const googleAnalytics = ({ googleAnalytics }) => {
    // console.log('Routes googleAnalytics')
    //primeiro tem prioridade

    //estabelecimento
    if (googleAnalytics) {
        window.dispatchEvent(
            new CustomEvent("criarGoogleAnalytics", {
                detail: {
                    tag: googleAnalytics,
                },
            })
        )
    }

    //cliente fiel (SITE PEDIDOS REACT)
    window.dispatchEvent(
        new CustomEvent("criarGoogleAnalytics", {
            detail: {
                tag: "UA-77355682-2",
            },
        })
    )

    //teste cliente fiel (PAINEL REACT)
    // window.dispatchEvent(new CustomEvent("criarGoogleAnalytics", {
    //   detail: {
    //     tag: "UA-77355682-3"
    //   }
    // }));
}

const tagManager = (aplicativoDados, estabelecimento) => {
    try {
        var tagsGoogle = sessionStorage.getItem("TagManager")

        if (!tagsGoogle) {
            tagsGoogle = []
        }

        if (aplicativoDados.tagManager) {
            console.log("TagManager.aplicativoDados ", aplicativoDados.tagManager)
            const tagManagerArgs = {
                gtmId: `${aplicativoDados.tagManager}`,
            }
            tagsGoogle.push(aplicativoDados.tagManager)
            TagManager.initialize(tagManagerArgs)
        }

        if (estabelecimento.tagManager) {
            const tagManagerArgs = {
                gtmId: `${estabelecimento.tagManager}`,
            }
            TagManager.initialize(tagManagerArgs)
            tagsGoogle.push(estabelecimento.tagManager)
            console.log("TagManager.estabelecimento ", estabelecimento.tagManager)
        }

        sessionStorage.setItem("TagManager", tagsGoogle)
    } catch (error) {}

    return null
}
