import { createContext, useContext, useEffect, useState } from "react"
import { getJSONParametros } from "../services/functions"
import SiteContext from "./SiteContext"

export const ParametrosContext = createContext(null)

export function ParametrosContextProvider(props) {
    const [parametros, setParametros] = useState({})

    const { estabelecimentoAtual } = useContext(SiteContext)

    useEffect(() => {
        const getParametros = async () => {
            const params = await getJSONParametros(estabelecimentoAtual.id)

            if (params) {
                setParametros(params)
                localStorage.setItem("custom-params", JSON.stringify(params))
            }
        }

        if (estabelecimentoAtual) getParametros()
    }, [estabelecimentoAtual?.id])

    return (
        <ParametrosContext.Provider value={{ parametros }}>
            {props.children}
        </ParametrosContext.Provider>
    )
}

const useParametros = () => {
    const context = useContext(ParametrosContext)

    if (!context) throw new Error("useParametros must be within a Context Provider")

    return context.parametros
}

export default useParametros
