import Button from "@material-ui/core/Button"
import React from "react"
import flatErro from "./assets/fail-flat.svg"
import { RegistrarLogErro } from "./services/functions"

export default class Exception extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            message: "",
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // alert(error)
        this.setState({
            hasError: true,
            message: "Erro: " + error,
        })

        RegistrarLogErro(error, "componentDidCatch", errorInfo)

        if (JSON.stringify(error).includes("ChunkLoadError")) {
            const hasRefreshed = JSON.parse(
                window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
            )

            if (!hasRefreshed) {
                window.sessionStorage.setItem("retry-lazy-refreshed", "true")
                window.location.reload()
            }
        }

        if (JSON.stringify(error).includes("Minified React error #185")) {
            const hasRefreshed = JSON.parse(
                window.sessionStorage.getItem("react-185-refreshed") || "false"
            )

            if (!hasRefreshed) {
                window.sessionStorage.setItem("react-185-refreshed", "true")
                window.location.reload()
            }
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        padding: "2em",
                        position: "absolute",
                    }}
                >
                    <img
                        src={flatErro}
                        alt={"loading erro"}
                        className="pretoEBranco"
                        style={{ width: "350px", marginBottom: "1em" }}
                    />
                    <h6>Houve um erro no aplicativo!</h6>
                    <p>
                        Tire um print dessa tela e envie para o estabelecimento, após isso resete o
                        app clicando no botão "RESETAR" para voltar ao normal.
                    </p>
                    <p>{this.state.message}</p>
                    <Button
                        variant="outlined"
                        color="default"
                        onClick={() => {
                            localStorage.clear()
                            window.location.href = window.location.href
                        }}
                    >
                        RESETAR
                    </Button>
                </div>
            )
        }

        return this.props.children
    }
}
