import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MobileStepper from "@material-ui/core/MobileStepper"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import DELIVERY from "../assets/svg/delivery.svg"
import FIDELIDADE from "../assets/svg/fidelidade.svg"
import RETIRAR from "../assets/svg/retirada.svg"
import TERMOS from "../assets/svg/termos.svg"
// import RETIRAR from '../assets/steppers/retirar.png'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const tutorialSteps = [
    {
        label: "San Francisco – Oakland Bay Bridge, United States",
        imgPath:
            "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
    },
    {
        label: "Bird",
        imgPath:
            "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
    },
    {
        label: "Bali, Indonesia",
        imgPath:
            "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
    },
    {
        label: "NeONBRAND Digital Marketing, Las Vegas, United States",
        imgPath:
            "https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60",
    },
    {
        label: "Goč, Serbia",
        imgPath:
            "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
    },
]

const useStyles = makeStyles(theme => ({
    root: {
        // maxWidth: 400,
        // flexGrow: 1,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    header: {
        display: "flex",
        alignItems: "center",
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        // height: 255,
        // display: 'block',
        maxWidth: 300,
        overflow: "hidden",
        width: "100%",
    },
}))

function SteppersDicas(props) {
    const { aceitarLGPD, open, aplicativoDados } = props
    const classes = useStyles()
    const theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0)

    const handleNext = () => {
        if (activeStep === maxSteps - 1) {
            aceitarLGPD()
        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1)
        }
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
    const handlePular = () => {
        if (steppers.length - 1 === activeStep) {
            aceitarLGPD()
        } else {
            setActiveStep(steppers.length - 1)
        }
    }

    const handleStepChange = step => {
        setActiveStep(step)
    }

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const steppers = [
        {
            id: "delivery",
            tem: aplicativoDados.possuiDelivery ? true : false,
            img: DELIVERY,
            title: "Delivery",
            description: "Faça seu pedido que entregamos rapidamente para você.",
        },
        {
            id: "fidelidade",
            tem: aplicativoDados.apenasDelivery ? false : true,
            img: FIDELIDADE,
            title: "Programa de Fidelidade",
            description:
                "Faça pedidos e convide amigos(as) para ganhar selos de fidelidade. Completando a cartela você troca por um desconto no próximo pedido.",
        },
        {
            id: "retirada",
            tem: aplicativoDados.permiteRetiradaBalcao ? true : false,
            img: RETIRAR,
            title: "Retirar No Local",
            description:
                "Em algumas lojas você pode solicitar buscar seu pedido no local ao em vez do motoqueiro.",
        },
        {
            id: "termos",
            tem: true,
            img: TERMOS,
            title: "Política de Privacidade",
            description: (
                <>
                    Ao continuar, você reconhece que leu e entendeu nossa{" "}
                    <a
                        targer="_blank"
                        href="https://appclientefiel.com.br/politicaprivacidade.html"
                    >
                        {" "}
                        Política de Privacidade e nossos Termos de Serviço
                    </a>
                    .
                </>
            ),
        },
    ]
    const maxSteps = steppers.length

    return open ? (
        <Dialog
            open={
                open &&
                !(
                    window.location.href.includes("downloads/imagensTematicas") ||
                    window.location.href.includes("login/pedidoMesa") ||
                    JSON.parse(localStorage.getItem("usuarioPedidoMesaCF"))?.logado
                )
            }
            fullScreen={fullScreen}
        >
            <DialogTitle id="pagamentoOnlineModal">
                <IconButton className="setaVoltarModal" onClick={handlePular}>
                    <ArrowForwardIosIcon />
                </IconButton>
                {steppers[activeStep].title}
            </DialogTitle>
            <DialogContent className={classes.root}>
                {/* <div className={classes.root}> */}
                {/* <Paper square elevation={0} className={classes.header}> */}
                {/* <Typography>{tutorialSteps[activeStep].label}</Typography> */}
                {/* </Paper> */}

                <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {steppers.map((step, index) => (
                        <div key={step.label} style={{ textAlign: "center" }}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <img className={classes.img} src={step.img} alt={step.title} />
                            ) : null}
                        </div>
                    ))}
                </SwipeableViews>
                <DialogContentText
                    style={{
                        marginBottom: "1em",
                        textAlign: "center",
                    }}
                >
                    <Typography
                        variant="p"
                        component="p"
                        align={"center"}
                        style={{
                            marginBottom: "1em",
                            fontSize: "1.4rem",
                        }}
                    >
                        {steppers[activeStep].description}
                    </Typography>
                </DialogContentText>

                {/* </div> */}
            </DialogContent>
            {/* <DialogActions> */}
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        color="primary"
                        // disabled={activeStep === maxSteps - 1}
                    >
                        {activeStep === maxSteps - 1 ? "Aceitar" : "Próximo"}
                        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button
                        size="small"
                        onClick={handlePular}
                        disabled={activeStep === maxSteps - 1}
                    >
                        {/* {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />} */}
                        Pular
                    </Button>
                }
                // backButton={
                // <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                //     {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                //     Anterior
                // </Button>
                // }
            />
            {/* <Button onClick={ () => {
            aceitarLGPD()
        }} color="primary" autoFocus fullWidth>
            OK
        </Button> */}
            {/* </DialogActions> */}
        </Dialog>
    ) : (
        <React.Fragment></React.Fragment>
    )
}

export default SteppersDicas
