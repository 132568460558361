import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import * as Sentry from "@sentry/react"
import { SnackbarProvider } from "notistack"
import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { LoadingContextProvider } from "./contexts/LoadingContext"
import Exception from "./Exception"
import "./global.css"
import { registerServiceWorker } from "./serviceWorker"
import { TextosContextProvider } from "./contexts/TextosContext"
registerServiceWorker()

if (!(localStorage.getItem("teste") || window.location.hostname.includes("localhost"))) {
    console.log = function () {}
    console.warn = function () {}
}

Sentry.init({
    dsn: "https://e2625ab3f53645f591e434b8559d16f5@o436862.ingest.sentry.io/5398602",
    ignoreErrors: [
        /^NotAllowedError: Failed to execute 'prompt'$/,
        /^NotAllowedError: The prompt() method must be called with a user gesture$/,
        /^NotAllowedError: Write permission denied.$/,
        /^NotAllowedError: Failed to execute 'prompt' on 'BeforeInstallPromptEvent': The prompt() method must be called with a user gesture$/,
        "NotAllowedError: Failed to execute 'prompt' on 'BeforeInstallPromptEvent': The prompt() method must be called with a user gesture",
        /^Error: NotAllowedError: Failed to execute 'prompt'$/,
        "Error: NotAllowedError: Failed to execute 'prompt' on 'BeforeInstallPromptEvent': The prompt() method must be called with a user gesture",
        /^execute 'prompt' on 'BeforeInstallPromptEvent'$/,
    ],
})

ReactDOM.render(
    <Exception>
        <AlertProvider />
    </Exception>,
    document.getElementById("root")
)

function AlertProvider() {
    const notistackRef = React.createRef()
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key)
    }

    useEffect(() => {
        window.dispatchEvent(new CustomEvent("abriSite"))
    }, [])

    return (
        <SnackbarProvider
            maxSnack={1}
            preventDuplicate
            ref={notistackRef}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            TransitionComponent={Collapse}
            action={key => (
                <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon />
                </IconButton>
            )}
        >
            <TextosContextProvider>
                <BrowserRouter>
                    <LoadingContextProvider>
                        <App />
                    </LoadingContextProvider>
                </BrowserRouter>
            </TextosContextProvider>
        </SnackbarProvider>
    )
}
